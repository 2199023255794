$(document).ready(function(){
    $('article.readmore').readmore({
        collapsedHeight: 186,
        moreLink: '<a href="#" class="read-link">Lire la suite</a>',
        lessLink: '<a href="#" class="read-link">Fermer</a>'
    });

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
    
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
});